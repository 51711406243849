export const tableData = {
  list: [
    {
      orderid: 1,
      ordername: "商城购买",
      ordernum: 10,
      ordertime: Date.now(),
      ordersprice: 222,
    },
    {
      orderid: 2,
      ordername: "商城购买",
      ordernum: 10,
      ordertime: Date.now(),
      ordersprice: 222,
    },
    // {
    //   orderid: 3,
    //   ordername: "商城购买",
    //   ordernum: 10,
    //   ordertime: "2023-11-01 14:00:00",
    //   ordersprice: 222,
    // },
    // {
    //   orderid: 4,
    //   ordername: "商城购买",
    //   ordernum: 10,
    //   ordertime: "2023-11-01 14:00:00",
    //   ordersprice: 222,
    // },
    // {
    //   orderid: 4,
    //   ordername: "商城购买",
    //   ordernum: 10,
    //   ordertime: "2023-11-01 14:00:00",
    //   ordersprice: 222,
    // },
    // {
    //   orderid: 4,
    //   ordername: "商城购买",
    //   ordernum: 10,
    //   ordertime: "2023-11-01 14:00:00",
    //   ordersprice: 222,
    // },
    // {
    //   orderid: 4,
    //   ordername: "商城购买",
    //   ordernum: 10,
    //   ordertime: "2023-11-01 14:00:00",
    //   ordersprice: 222,
    // },
  ],
};
