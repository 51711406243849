<template>
  <div class="view-order-list" :class="{'mb_orderbox':isMinDevice}">
    <!-- 订单列表 -->
    <div class="mb-title" v-show="isMinDevice">Order Record</div>
    <div class="order-list-box" :class="{'mb_list':isMinDevice}">
      <div class="select-box" :class="{'mb_serchbox':isMinDevice}">
        <Cselect v-if="!isMinDevice" :selectList="selectList" v-model="selectItem" @input="dotype" />
        <Cselect v-if="isMinDevice" :width='6.5' :height="1.1265" :selectList="selectList" v-model="selectItem" @input="dotype" />
        <p v-if="!isMinDevice">If you have any questions, <span class="clicktext point" @click="kefuclick"> please contact customer
            service</span></p>
        <p v-if="isMinDevice" style="font-size: 0.6rem;padding:0 0 0 0.2rem">If you have any questions, <span class="clicktext point" @click="kefuclick"> please contact customer
            service</span></p>
      </div>
      <div class="table-box" :class="{'mb_tablebox':isMinDevice}">
        <div class="common-table" :class="{'mb_common_table':isMinDevice}">
          <div class="left-common flex-s table-header" :class="{'mb_table_header':isMinDevice}">
            <div style="flex-grow: 1">Order number</div>
            <div>commodity</div>
            <div>Num</div>
            <div>Time</div>
            <div>Price</div>
            <div>Status</div>
            <!-- <div style="width: 0.16rem"></div> -->
          </div>
          <div class="table-body" style="height: 5.43rem" :class="{'mb_table_bodys':isMinDevice}">
            <div v-for="(item) in listdata" :key="item.id" class="left-common flex-s point" @click="
              tipsshow(item, item.order_sn, item.createtime, item.orderstate)
              " style="box-sizing: border-box" :class="{'mb_table_items':isMinDevice}">
              <div style="flex-grow: 1;font-size: 0.14rem; display: flex;justify-content: flex-start;padding-left: 0.1rem;}" :class="{'mb_sns':isMinDevice}">{{ item.order_sn }}</div>
              <div>
                <div class="good-img" :class="{'mb_imggood':isMinDevice}">
                  <img class="h100" :src="item.order_goods[0]?.image" alt="" />
                </div>
                <div class="text" style="width: 80%" v-if="!isMinDevice">
                  {{ item.order_goods[0]?.title }}
                </div>
              </div>
              <div>{{ item.order_goods.length }}</div>
              <div style="text-align: center;padding-left: 0.45rem; box-sizing: border-box;" :style="isMinDevice?'font-size: 0.5rem':'' ">
                {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy-hh:mm:ss") }}
              </div>
              <div>${{ item.amount }}</div>
              <div>
                <div v-show="item.orderstate == 0" style="color: #000000">
                  Unfinished
                </div>
                <div v-show="item.orderstate == 2" style="color: #a6a6a6">
                  Expired
                </div>
                <div v-show="item.orderstate == 3" style="color: #00bb1e">
                  Completed
                </div>
                <div v-show="item.orderstate == 1" style="color: #F6841C">
                  In Shipment
                </div>
                <div v-show="item.orderstate == 4" style="color: #00bb1e">
                  After-sales
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-pagination" :class="{'mb-common-page':isMinDevice}">
      <el-pagination :current-page.sync="page" layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="pageSize" :total="total">
      </el-pagination>
    </div>
    <div class="detailshow" v-show="orderview">
      <div class="box" v-show="orderview" :class="{'mb_box':isMinDevice}">
        <!-- 订单详情 -->
        <div class="title" :class="{'mb_title':isMinDevice}">
          Order Details
          <img class="point" src="@/assets/images/pc/pc-home-icon10.png" alt="" @click="() => {
            (this.orderview = false), (this.detailshow = false);
          }
            " />
        </div>
        <div class="main">
          <div class="timeinfo" v-show="!isMinDevice">
            <div class="item">Order No:{{ order_sn }}</div>
            <div class="item">
              Creation time:{{
                $formatTime(order_time * 1000, "yyyy-MM-dd-hh:mm:ss")
              }}
            </div>
          </div>
          <div v-show="isMinDevice">
            <div class="mb_commenitem">Order No:{{ order_sn }}</div>
            <div class="mb_commenitem">
              Creation time:{{
                $formatTime(order_time * 1000, "yyyy-MM-dd-hh:mm:ss")
              }}
            </div>
          </div>
          <div class="infobox" :class="{'mb_infobox':isMinDevice}">
            <div class="tabletitle" v-if="!isMinDevice">
              <div style="width: 60%">Products</div>
              <div style="width: 40%; text-align: center">Amount</div>
              <div style="width: 40%; text-align: center">Price</div>
              <div style="width: 40%; text-align: center">Status</div>
            </div>
            <div class="tablebady" :class="{'mb_tablebox':isMinDevice}">
              <div v-for="(item) in detaillist" :key="item.id" class="titem" :class="{'mb_titem':isMinDevice}">
                <div class="imgbox" :class="{'mb_imgbox':isMinDevice}">
                  <img :src="item.image" alt="" />
                  <p>{{ item.title }}</p>
                </div>
                <div style="width: 40%; text-align: center" :style="isMinDevice? 'font-size:0.6rem':'' ">
                  {{ item.nums }}
                </div>
                <div style="width: 40%; text-align: center" :style="isMinDevice? 'font-size:0.6rem':'' ">
                  ${{
                    isVip
                    ? (item?.price * item?.nums).toFixed(2)
                    : (item?.marketprice * item?.nums).toFixed(2)
                  }}
                </div>
                <div v-if="item.status==0" style="width: 40%; text-align: center" :style="isMinDevice? 'font-size:0.6rem':'' ">
                  In Shipment
                </div>
                <div v-if="item.status==1" style="width: 40%; text-align: center" :style="isMinDevice? 'font-size:0.6rem':'' ">
                  Completed
                </div>
                <div v-if="item.status==2" style="width: 40%; text-align: center" :style="isMinDevice? 'font-size:0.6rem':'' ">
                  refunded
                </div>
              </div>
            </div>
            <div class="Totelbox" :class="{'mb_totelbox':isMinDevice}">
              Total：$ {{cretTotelprice}}
            </div>
            <div v-if="order_state===4" class="Totelbox" style="color:red" :class="{'mb_totelbox':isMinDevice}">
              Refund price:$ {{Refundprice}}
            </div>
          </div>
          <div class="btnbox" :class="{'mb_btnbox':isMinDevice}">
            <div class="btn point" :class="{'mb_btn':isMinDevice}" @click="() => {
                (this.orderview = false),
                (this.detailshow = false);
            }
              ">
              <p>Cancel</p>
            </div>
            <div class="btn point" :class="{'mb_btn':isMinDevice}" v-show="order_state === 0" @click="() => {
              (this.acindex = 1), gopay();
            }
              ">
              Check Out
            </div>
            <div class="btn point" :class="{'mb_btn':isMinDevice}" v-show="order_state === 3" @click="() =>
              this.$router.replace({
                path: '/User',
                query: {
                  TabIndex: 2,
                  order_sn
                },
              })
              ">
              View Warehouse
            </div>
            <div class="btn point" :class="{'mb_btn':isMinDevice}" v-show="order_state === 4" @click="() =>
              this.$router.replace({
                path: '/User',
                query: {
                  TabIndex: 2,
                  order_sn
                },
              })
              ">
              View Warehouse
            </div>
            <div class="btn point" :class="{'mb_btn':isMinDevice}" v-show=" order_state === 1" @click="() =>
              this.$router.replace({
                path: '/User',
                query: {
                  TabIndex: 2,
                  order_sn
                },
              })
              ">
              View Warehouse
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderListApi, orderPayApi } from "../../network/api";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      selectItem: "All",
      page: 1,
      ordertype: "", //默认全部
      listdata: [],
      detailshow: false, //提示蒙层
      orderview: false,
      acindex: 1,
      detaillist: [], //点击详情列表
      order_sn: "", // 当前订单号
      order_time: "", //当前订单时间
      order_state: 0, //当前状态
      page: 1, //页码
      pageSize: 4, //当前页面
      total: 1, //总条数
    };
  },
  created() {
    this.getlstdata();
  },
  methods: {
    kefuclick() {
      drift.api.toggleChat();
    },
    ...mapActions(["getCartList", "ReFreshUserInfo"]),
    async getlstdata() {
      const res = await getOrderListApi(this.page, this.ordertype);
      this.listdata = res.data.Items;
      this.pageSize = res.data.ItemsPerPage;
      this.total = res.data.TotalItems;
    },
    dotype(v) {
      if (v == "unpaid") {
        this.ordertype = 0;
        this.getlstdata();
      } else if (v == "expired") {
        this.ordertype = 2;
        this.getlstdata();
      } else if (v == "done") {
        this.ordertype = 3;
        this.getlstdata();
      } else if (v == "In Shipment") {
        this.ordertype = 1;
        this.getlstdata();
      } else if (v == "After-sales") {
        this.ordertype = 4;
        this.getlstdata();
      } else if (v == "All") {
        this.ordertype = "";
        this.getlstdata();
      }
    },
    tipsshow(v, sn, t, s) {
      this.detailshow = true;
      this.orderview = true;
      this.detaillist = v.order_goods;
      this.order_sn = sn;
      this.order_time = t;
      this.order_state = s;
    },
    // 支付
    async gopay() {
      const params = {
        order_sn: this.order_sn,
      };
      const res = await orderPayApi(params);
      if (res.code == 1) {
        this.getlstdata();
        (this.detailshow = false), (this.orderview = false);
        this.ReFreshUserInfo();
      } else {
        this.$message.error(res.msg);
      }
    },
    handleCurrentChange(val) {
      this.getlstdata(val, this.ordertype);
    },
  },
  computed: {
    selectList() {
      return ["All", "unpaid", "expired", "done", 'In Shipment', 'After-sales'];
    },
    ...mapGetters(["isVip"]),
    ...mapState(['isMinDevice']),

    cretTotelprice() {
      let num = 0;
      this.detaillist.forEach(item => {
        if (this.isVip) {
          num += item?.price * item?.nums;
        } else {
          item?.marketprice * item?.nums;
        }
      });
      return num.toFixed(1);
    },
    Refundprice() {
      let num = 0;
      this.detaillist.forEach(item => {
        if (item.status == 2) {
          if (this.isVip) {
            num += item?.price * item?.nums;
          } else {
            item?.marketprice * item?.nums;
          }
        }
      });
      return num.toFixed(1);
    }
  },
};
</script>

<style lang="scss">
.view-order-list {
  padding: 0.15rem;
  box-sizing: border-box;
  height: 100%;

  .order-list-box {
    padding: 0.15rem;
    box-sizing: border-box;
    background: rgba(246, 250, 255, 1);
    width: 100%;
    height: 6.8rem;

    .select-box {
      height: 0.4rem;
      margin-bottom: 0.24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .clicktext {
        color: #0076ff;
        text-decoration: underline;
      }
    }
    .mb_serchbox {
      width: 17.152rem;
      height: 1.2rem;
      margin: 0 0 0.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffff;
      flex-wrap: wrap;
    }

    .table-box {
      width: 100%;

      .table-body {
        font-size: 0.16rem;

        .good-img {
          width: 1rem;
          height: 1.15rem;
        }
        .mb_imggood {
          width: 3.2rem;
          height: 4.23rem;
          margin: 0 0.1rem;
        }
      }
      .mb_table_bodys {
        height: 21rem !important;
      }
    }
    .mb_tablebox {
      background: rgba(246, 250, 255, 1);
      margin-top: 2rem;
    }
  }
  .mb_list {
    background: #fff;
    height: 25rem;
    overflow: hidden;
  }

  .common-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .common-pagination {
    width: 10.93rem;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
  .mb_table_items {
    height: 5.1rem !important;
    font-size: 0.5rem;
  }
  .mb_sns {
    word-break: break-all !important;
    font-size: 0.4rem !important;
    margin: 0 0.5rem !important;
  }
  .detailshow {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 1999;

    .box {
      width: 8.5rem;

      .title {
        width: 100%;
        height: 0.8rem;
        background: #002550;
        text-align: center;
        line-height: 0.8rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        border-radius: 0.12rem 0.12rem 0 0;
        position: relative;

        img {
          display: block;
          width: 0.35rem;
          height: 0.35rem;
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .mb_title {
        height: 1.5rem;
        font-size: 0.7rem;
        line-height: 1.5rem;
        border-radius: 0.2rem 0.2rem 0 0;
        img {
          width: 0.87rem;
          height: 0.87rem;
        }
      }

      .main {
        width: 100%;
        background: #fff;
        border-radius: 0 0 0.12rem 0.12rem;

        .timeinfo {
          width: 7.7rem;
          height: 0.9rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;

          .item {
            // width: 50%;
            height: 0.4rem;
            font-size: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 0.4rem;
            text-align: left;
          }
        }

        .infobox {
          width: 7.7rem;
          margin: 0 auto;

          .tabletitle {
            width: 100%;
            height: 0.5rem;
            background: #003879;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 0.2rem;
            font-size: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }

          .tablebady {
            width: 100%;
            height: 2.6rem;
            overflow-y: scroll;
            background: #f6faff;

            .titem {
              width: 100%;
              height: 1.05rem;
              margin: 0.15rem 0;
              display: flex;
              align-items: center;
              box-sizing: border-box;

              .imgbox {
                width: 60%;
                height: 1.05rem;
                display: flex;
                align-items: center;
                padding-left: 0.1rem;

                img {
                  height: 100%;
                }
              }
              .mb_imgbox {
                width: 25rem;
                height: 3.7rem;
                margin-left: 0.2rem;
                p {
                  font-size: 0.6rem;
                }
              }
            }
            .mb_titem {
              height: 4.6rem;
              border-bottom: 0.05rem solid #d3dbe4;
            }
          }
          .mb_tablebox {
            height: 12rem;
            background: #fff;
          }
          .Totelbox {
            height: 0.3rem;
            line-height: 0.3rem;
            padding-right: 0.2rem;
            font-size: 0.18rem;
            display: flex;
            justify-content: flex-end;
          }
          .mb_totelbox {
            height: 1.7rem;
            font-size: 0.6rem;
            line-height: 1.7rem;
            padding-right: 1rem;
            font-weight: 500;
            border-bottom: 0.05rem solid #d3dbe4;
          }
        }
        .mb_infobox {
          width: 17.2rem;
        }
        .mb_commenitem {
          width: 100%;
          height: 1.7rem;
          font-size: 0.6rem;
          line-height: 1.7rem;
          border-bottom: 0.05rem solid #d3dbe4;
          padding-left: 0.2rem;
        }

        .btnbox {
          width: 7.7rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 auto;

          .btn {
            width: 3.18rem;
            height: 0.6rem;
            display: flex;
            justify-content: center;
            background: #0076ff;
            align-items: center;
            font-size: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .mb_btnbox {
          width: 16rem;
          height: 1.85rem;
          padding: 0 0.2rem;
          .mb_btn {
            width: 6.3rem;
            height: 1.28rem;
            font-size: 0.6rem;
            line-height: 1.28rem;
          }
        }
      }
    }
    .mb_box {
      width: 17.2rem;
    }
  }
}
.mb_orderbox {
  width: 17.152rem;
  margin: 0.3rem auto;
  background: #fff;
  padding: 0;
}
</style>