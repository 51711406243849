<template>
  <div>
    <div class="pointbox" :class="{'mb_pointbox':isMinDevice}">
      <!-- 积分 -->
      <div class="mb-title" v-show="isMinDevice">Integral Record</div>
      <div class="tablebox" :class="{'mb_tablebox':isMinDevice}">
        <div class="common-table" :class="{'mb_common_table':isMinDevice}">
          <div class="left-common flex-s table-header" :class="{'mb_table_header':isMinDevice}">
            <div style="flex-grow: 1">Time</div>
            <div>Type</div>
            <div>Before</div>
            <div>Change</div>
            <div>After</div>
          </div>
          <div class="table-body" :class="{'mb_table_bodys':isMinDevice}">
            <div v-for="item, index in pointlist" :key="index" class="left-common flex-s" style="height: 0.5rem;" :class="{'mb_table_items':isMinDevice}">
              <div style="flex-grow: 1" :style="isMinDevice?'font-size: 0.4rem':'' ">
                {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy-hh:mm:ss") }}
              </div>
              <div>{{ item.score > 0 ? 'Recharge' : 'consume' }}</div>
              <div>{{ item.before }}</div>
              <div>{{ item.score }}</div>
              <div>{{ item.after }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-pagination" :class="{'mb-common-page':isMinDevice}">
      <el-pagination :current-page.sync="page" layout='prev, pager, next, jumper' @current-change="handleCurrentChange" :page-size="pageSize" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPointApi } from '../../network/api';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      page: 1, //页码
      pageSize: 8,//当前页面
      total: 1,//总条数

      pointlist: []//积分列表
    };
  },
  created() {
    this.getdata(this.page);
  },
  computed: {
    ...mapState(['isMinDevice'])
  },
  methods: {
    async getdata(page) {
      const res = await getPointApi(page);

      this.pointlist = res.data.Items;
      this.pageSize = res.data.ItemsPerPage;
      this.total = res.data.TotalItems;
    },
    handleCurrentChange(val) {
      this.getdata(val);
    }
  }
}
</script>

<style lang="scss" scoped>
.pointbox {
  width: 10.93rem;
  height: 6.4rem;
  margin: 0.3rem auto;
  background: #f6faff;
  overflow: hidden;

  .tablebox {
    width: 10.21rem;
    margin: 0.3rem auto;
  }
  .mb_tablebox {
    width: 100%;
    background: #f6faff;
    padding-bottom: 0.5rem;
  }
  .mb_table_items {
    height: 1.9rem !important;
    font-size: 0.5rem;
  }
}
.mb_pointbox {
  width: 17.152rem;
  height: 22rem;
  margin: 0.3rem auto;
  background: #fff;
}
.mb_table_bodys {
  height: 18rem !important;
}
.common-pagination {
  width: 10.93rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
</style>