<template>
  <div class="sensingbox" :class="{'mb_sensingbox':isMinDevice}">
    <!-- 检测 -->
    <div class="mb-title" v-show="isMinDevice">Key Check</div>
    <div class="title" :class="{'mb_titletext':isMinDevice}">
      The homepage allows for batch key detectio,And the detected key will be
      encrypted. We hate platforms that steal keys and charge high fees. VIPs
      can receive the detection source code for free.
    </div>
    <div class="putcode">
      <Cselect v-if="isMinDevice" :width='5.12' :height='1.1264' :selectList="selectList" v-model="selectItem" />
      <div class="name" :class="{'mb_name':isMinDevice}" style="display: flex; align-items: center">
        Key Detection
        <Cselect v-if="!isMinDevice" style="margin-left: 0.5rem" :selectList="selectList" v-model="selectItem" />
      </div>
      <div class="putbox">
        <input class="put" :class="{'mb_put':isMinDevice}" type="text" placeholder="Please enter the key content" v-model="codevalue" />
        <div class="btn point" :class="{'mb_btn':isMinDevice}" @click="docheck">Checking</div>
      </div>
    </div>
    <div class="endbox">
      <div class="name" :class="{'mb_name':isMinDevice}">Detection Results</div>
      <div class="info">
        <div class="box" :class="{'mb_box':isMinDevice}" v-loading="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading" element-loading-background="rgba(240, 240, 240, 0.7)">
          <div v-if="this.checkvluered.length > 0">
            <div v-for="(item, index) in checkvluered" :key="index" style="margin-top: 0.12rem" :style="isMinDevice?'font-size: 0.6rem':'' ">
              <div>Key: {{ item.Key }}</div>
              <div>Description: {{ item.Description }}</div>
              <div>Version: {{ item.Version }}</div>
              <div style="color: red; display: flex; align-items: center; height: 0;margin-top: 0.1rem;">
                Result:{{ status }}
              </div>
            </div>
          </div>
        </div>
        <div class="btn point" :class="{'mb_btn':isMinDevice}" @click="copys">Duplication</div>
      </div>
    </div>
    <div class="Batch point" :class="{'mb_batch':isMinDevice}" @click="tocheckpage">
      <p>Batch Checking</p>
    </div>
  </div>
</template>

<script>
import { checkKeyApi } from "../../network/api";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      codevalue: "",
      checkvluered: [], //rem的
      selectItem: "redeemms",
      text: "",
      loading: false,
      loadingText: "loading1%",
      Timer: null,
      status: ''//状态码
    };
  },
  methods: {
    async docheck() {
      if (this.codevalue != "") {
        if (this.loading) {
          this.$message.error("Testing in progress, please wait");
          return false;
        }
        if (this.Timer) {
          clearInterval(this.Timer);
        }
        this.loading = true;
        let num = 1;
        this.Timer = setInterval(() => {
          if (num < 99) {
            num++;
            this.loadingText = `loading${num}%`;
          }
        }, 2000);
        const res = await checkKeyApi(this.codevalue, this.selectItem);
        if (res.code == 1) {
          this.loading = false;
          setTimeout(() => {
            this.loadingText = `loading1%`;
          }, 300);
          this.checkvluered = [];
          this.checkvluered = res.data.map((item) => {
            console.log(item, "获得数据");
            return {
              Key:
                this.selectItem == "pid"
                  ? item.keyname_with_dash
                  : item.keyname,
              Description: item.prd,
              status: this.selectItem == "pid" ? item.errorcode : item.status,
              Version: this.selectItem == "pid" ? item.sub : item.sub,
            };
          });
          switch (this.checkvluered[0]?.status) {
            case '0xC004C008':
              this.status = 'phone key';
              break;
            case '0xC004C060':
              this.status = 'Fail key';
              break;
            case '0xC004C003':
              this.status = 'Blocked key';
              break;
            case 'Online key':
              this.status = 'online key';
              break;
            case 'Acrapped':
              this.status = 'Blocked key';
              break;
            case 'Active':
              this.status = 'Active';
              break;
            case 'Redeemed':
              this.status = 'Used keys';
              break;
            case 'Scrapped':
              this.status = 'Blocked key';
              break;
          }
          this.$message.success(res.msg);
        }
      } else {
        this.$message.error("Please enter the key");
      }
    },
    async copys() {
      if (this.selectItem == "pid") {
        this.text = `Key:${this.checkvluered[0].Key} Description:${this.checkvluered[0].Description} Result: ${this.status} Version:${this.checkvluered[0].Version}`;
      } else if (this.selectItem == "redeemms") {
        this.text = `Key:${this.checkvluered[0].Key} Description:${this.checkvluered[0].Description} Result: ${this.status} Version:${this.checkvluered[0].Version}`;
      }
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        this.$message.success("Copy Success");
        return navigator.clipboard.writeText(this.text);
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = this.text;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
          this.$message.success("Copy Success");
        });
      }
    },
    tocheckpage() {
      this.$router.push("/CheckKeys");
    },
  },

  computed: {
    selectList() {
      return ["redeemms","pid"];
    },
    ...mapState(['isMinDevice'])
  },
};
</script>

<style lang="scss" scoped>
.sensingbox {
  width: 10.93rem;
  margin: 0.3rem auto;

  .title {
    width: 100%;
    height: 0.8rem;
    font-size: 0.18rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #898989;
    line-height: 0.21rem;
  }
  .mb_titletext {
    height: 3.5rem;
    font-size: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
  }

  .putcode {
    width: 100%;
    margin-top: 0.4rem;

    .putbox {
      width: 100%;
      display: flex;
      align-items: center;

      .put {
        width: 6.48rem;
        height: 0.4rem;
        border: 0.01rem solid #9c9c9c;
        text-decoration: none;
        padding-left: 0.2rem;
        box-sizing: border-box;

        &::placeholder {
          color: #b8b8b8;
        }
      }
      .mb_put {
        width: 13rem;
        height: 1.07rem;
        font-size: 0.6rem;
      }
    }
  }

  .endbox {
    margin-top: 0.6rem;

    .info {
      display: flex;

      .box {
        width: 6.48rem;
        height: 2rem;
        border: 0.01rem solid #9c9c9c;
      }
      .mb_box {
        width: 13rem;
        height: 6.35rem;
      }
    }
  }

  .Batch {
    width: 2.01rem;
    height: 0.4rem;
    margin-top: 0.6rem;
    background: #0076ff;
    border-radius: 0.02rem;
    color: #ffff;
    margin-left: 0.1rem;
    text-align: center;
    line-height: 0.4rem;
  }
  .mb_batch {
    width: 5.376rem;
    height: 1.07rem;
    font-size: 0.6rem;
    line-height: 1.07rem;
    border-radius: 0.1rem;
  }
  .name {
    height: 0.25rem;
    font-size: 0.18rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 0.21rem;
    margin-bottom: 0.25rem;
  }

  .mb_name {
    font-size: 0.7rem;
    margin: 0.2rem 0;
    height: 1rem;
  }

  .btn {
    width: 1.45rem;
    height: 0.4rem;
    background: #0076ff;
    border-radius: 0.02rem;
    color: #ffff;
    margin-left: 0.1rem;
    text-align: center;
    line-height: 0.4rem;
  }
  .mb_btn {
    width: 3.84rem;
    height: 1.07rem;
    font-size: 0.6rem;
    line-height: 1.07rem;
    border-radius: 0.1rem;
  }
}
.mb_sensingbox {
  width: 17.152rem;
  // height: 22.1184rem;
  margin: 0.3rem auto;
  background: #fff;
}
</style>